body {
  min-height: auto;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 30px;
  line-height: 1.4;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}

p {
  margin-bottom: 15px;
  color: #6f6f71;
}

.embed-container {
  position: relative;
  max-width: 1340px;
  min-height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 15px;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-family: 'PT Sans', sans-serif;
  color: #2e2e2e;
  line-height: 1.6;
}

.tabs-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 2em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tabs-wrapper.is--v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tab-menu {
  /* position: -webkit-sticky; */
  /* position: sticky; */
  top: 1em;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18%;
  max-height: 90vh;
  padding-right: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tab-menu.is--v2 {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
  width: 18%;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 1em;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-shadow: none;
}

.tab-menu.is--v2b {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
  width: 18%;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 1em;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-shadow: none;
}

.tab-menu.is--v2e {
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
  width: 18%;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 1em;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-shadow: none;
}

.tab-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 82%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.tab-content.is--v2 {
  width: 82%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tab-content.is--v2b {
  width: 82%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tab-content.is--v2e {
  width: 82%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 1.4em 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  background-image: none;
  text-decoration: none;
}

.tab:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.tab.w--current {
  background-color: #000;
  color: #fff;
}

.tab.overview {
  padding: 1.4em 1em;
}

.tab.overview.is--v2 {
  width: 100%;
  margin-bottom: 0px;
}

.tab.is--v2 {
  padding: 1.4em 1em;
}

.tab.is--v2.is--no-click {
  margin-bottom: 0px;
  padding-left: 4px;
  background-color: transparent;
  font-weight: 700;
}

.tab-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tab-inner.is--v2 {
  padding-bottom: 0px;
}

.tab-title {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #25784b;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.tab-title.is--v2 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom-style: none;
}

.tab-h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.tab-h3.clear {
  margin-top: 0px;
  margin-bottom: 0px;
}

.thirds-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 10px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.thirds-grid.is--v2 {
  margin-bottom: 2em;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.block {
  padding: 16px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 6px;
  background-color: #fff;
  color: #2e2e2e;
  text-decoration: none;
}

.block.feature-value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.block.bar-chart {
  padding: 10px;
}

.block.block--placeholder {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.02);
}

.block.status-update {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.block.is--story {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-height: none;
  height: 100%;
}

.block.is--story:hover {
  border-color: rgba(0, 0, 0, 0.25);
}

.block.is--story.is--quote {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.swiper-container {
  display: none;
}

.swiper-slide {
  height: unset !important;
  min-height: 100% !important;
}

.swiper-button-prev,
.swiper-button-next {
  margin-top: -160px !important;
  padding: 10px;
  background: rgb(111, 197, 160, 1) !important;
  border-radius: 5px;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 12px !important;
  color: #fff;
  font-weight: bold;
}


.block.is--performance-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.block.is--story-modal {
  position: fixed;
  width: 650px;
  max-width: 95%;
  padding: 1.5em;
}

.block.is--phased-achievement {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.block.is--phased-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.icon {
  width: 56px;
  height: 56px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
}

.icon.icon--jobs-created {
  background-image: url('../images/job-opportunities-icon.svg');
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: repeat;
}

.icon.icon--jobs-retained {
  background-image: url('../images/Jobs-retained.svg');
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: repeat;
}

.icon.icon--support {
  background-image: url('../images/General-assistance.svg');
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--budget {
  background-image: url('../images/allocated-budget-icon.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--beneficiaries {
  background-image: url('../images/Beneficiaries.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--opportunities-progress {
  background-image: url('../images/Opportunities-in-progress.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--female {
  background-image: url('../images/female-beneficiaries.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--youth {
  background-image: url('../images/youth-beneficiaries.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--performance-1 {
  background-image: url('../images/phase-1-achievements.svg');
  background-position: 0px 0px;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.icon.icon--performance-2 {
  background-image: url('../images/phase-2-achievements.svg');
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: no-repeat;
}

.feature-value__label {
  color: #25784b;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
}

.feature-value__amount {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.feature-value__amount.feature-value__amount--green {
  color: #38aa50;
}

.feature-value__amount.feature-value__amount--yellow {
  color: #cfb72f;
}

.feature-value__amount.feature-value__amount--red {
  color: #cf452f;
}

.feature-value__amount.is--v2 {
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__amount.is--phase-1 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__amount.is--phase-2 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__amount.is--phase-2 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.bar-chart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bar-chart__row {
  position: relative;
  display: -ms-grid;
  display: grid;
  height: auto;
  margin-right: -6px;
  margin-left: -6px;
  padding: 4px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 16px;
  -ms-grid-columns: 60px 1fr;
  grid-template-columns: 60px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 6px;
  background-image: none;
  color: #6c6c6c;
  text-decoration: none;
}

.bar-chart__row:hover {
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#f5f5f5));
  background-image: linear-gradient(180deg, #f5f5f5, #f5f5f5);
  color: #000;
}

.bar-chart__row_label {
  position: relative;
  z-index: 1;
}

.bar-chart__row_bar {
  width: 100%;
  height: 10px;
  border-radius: 4px;
  background-color: #f5821f;
}

.bar-chart__row_bar.no-data--bar {
  background-color: rgba(0, 0, 0, 0.1);
}

.bar-chart__row_bar.is--phase-1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #004069;
}

.bar-chart__row_bar.is--dummy-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bar-chart__row_bar.is--dummy-2 {
  width: 60%;
}

.bar-chart__row_bar.is--dummy-3 {
  width: 40%;
}

.bar-chart__row_bar.is--dummy-4 {
  width: 20%;
}

.bar-chart__row_bar.is--dummy-5 {
  width: 10%;
}

.bar-chart__row_icon {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.button-wrap {
  margin-top: 10px;
  margin-right: -10px;
  margin-bottom: 10px;
}

.button-wrap.is--v2 {
  margin-top: 2em;
}

.button-wrap select {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #25784b;
  color: #fff;
  padding: 0.6em 1em;
}

.button-wrap select option {
  background-color: #fff;
  color: #333;
}

.button {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #25784b;
  background-image: none;
  text-decoration: none;
}

.button:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.button.button--secondary {
  background-color: #999;
}

.button.is--performance-cta {
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 1em;
}

.tab-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-share__label {
  color: #999;
}

.share-icon__image {
  width: 16px;
  height: 16px;
}

.share-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.04);
  background-image: none;
}

.share-icon:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.header {
  display: -ms-grid;
  display: grid;
  margin-top: 2em;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.header.is--v2 {
  margin-top: 2em;
  margin-bottom: 1em;
  -ms-grid-columns: 1fr minmax(auto, -webkit-max-content);
  -ms-grid-columns: 1fr minmax(auto, max-content);
  grid-template-columns: 1fr minmax(auto, -webkit-max-content);
  grid-template-columns: 1fr minmax(auto, max-content);
}

.header__label {
  color: #999;
}

.bar-chart__row_inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bar-chart__row_value {
  width: 80px;
  margin-right: -80px;
  margin-left: 6px;
  font-size: 12px;
  white-space: nowrap;
}

.bar-chart__row_value.small {
  position: relative;
  z-index: 1;
  width: 60px;
  margin-right: -60px;
}

.feature-value__line-graph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
}

.line-graph__period {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -1px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.04);
  color: #999;
}

.line-graph__period:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.line-graph__period_label {
  margin-top: 4px;
  margin-right: 4px;
  font-size: 10px;
  line-height: 1;
}

.feature-value__bar-chart {
  width: 100%;
}

.uppercase-label {
  width: 100%;
  margin-bottom: 10px;
  padding-top: 15px;
  font-family: Poppins, sans-serif;
  color: #999;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.uppercase-label.hide-mobile.is--menu-heading {
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.uppercase-label.uppercase-label--border-top {
  margin-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-value__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.embed-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
}

.page-header {
  display: -ms-grid;
  display: grid;
  padding-bottom: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.department-menu-button {
  display: none;
  padding: 6px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #25784b;
  background-image: none;
  color: #fff;
  cursor: pointer;
}

.department-menu-button:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.menu-open-icon {
  margin-right: 6px;
}

.header-description {
  max-width: 66.6666666666%;
}

.header-description.is--v2 {
  max-width: 100%;
}

.menu-close-icon {
  margin-right: 6px;
}

.feature-value__header_chart {
  width: 56px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.feature-value__header_chart.right {
  margin-left: auto;
}

.bar-chart__row_tooltip {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 100%;
  display: none;
  height: 20px;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 4px;
  background-color: #000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
}

.hidden {
  display: none;
}

.feature-value__split-chart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  font-size: 12px;
}

.demo-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #fff;
}

.demo-bar.left {
  width: 50%;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #b7363f;
  color: #fff;
  line-height: 1;
}

.demo-bar.right {
  padding-right: 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #25784b;
  line-height: 1;
  text-align: right;
}

.feature-value__number-percent {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
}

.basic-description {
  max-width: none;
  margin-bottom: 0px;
}

.text-green {
  color: #19aa51;
}

.text-yellow {
  color: #ca9e57;
}

.text-red {
  color: #ce1f1f;
}

.feature-value__value-description {
  min-height: 1.2em;
  margin-top: 0.2em;
  color: #6f6f71;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.logo {
  position: relative;
  z-index: 1;
  width: 400px;
}

.header-graphic {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 200px;
  padding-left: 2em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.02);
}

.header-image {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  height: 200px;
}

.heading {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}

.div-block-2 {
  padding: 2em;
}

.text-block-2 {
  margin-bottom: 19px;
  font-weight: 700;
}

.tab-header {
  position: relative;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-value__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feature-value__header.is--phased {
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 450px;
  margin-top: 10px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 4px;
  background-image: url('../images/Group-100-1.png');
  background-position: 50% 80%;
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-value__header_info-wrapper {
  padding-left: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feature-value__header_info-wrapper.is--phased {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.line-graph__period_tooltip {
  position: relative;
  left: -50%;
  display: none;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #000;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
}

.line-graph__period_tooltip-notch {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 6px;
  height: 6px;
  margin-right: auto;
  margin-bottom: -3px;
  margin-left: auto;
  background-color: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.line-graph__period_info-items {
  position: absolute;
  top: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.line-graph__period_marker {
  width: 8px;
  height: 8px;
  margin-top: 8px;
  margin-bottom: -4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #124069;
}

.line-graph__period_marker.is--dummy-1 {
  margin-bottom: 11px;
}

.line-graph__period_marker.is--dummy-2 {
  margin-bottom: 20px;
}

.line-graph__period_marker.is--dummy-3 {
  margin-bottom: 26px;
}

.line-graph__period_marker.is--dummy-4 {
  margin-bottom: 40px;
  background-color: #f5821f;
}

.line-graph__period_marker.is--dummy-5 {
  margin-bottom: 47px;
  background-color: #f5821f;
}

.line-graph__period_marker.is--phase-2 {
  background-color: #f5821f;
}

.feature-value__header_chart-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 16px;
  padding-left: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.status-update__description {
  width: 100%;
  margin-bottom: -15px;
}

.components {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
}

.components.is--hidden {
  display: none;
}

.loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
}

.loading-gif {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.feature-value__header_icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.feature-value__current-status {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
}

.feature-value__current-status.feature-value__current-status--green {
  color: #38aa50;
}

.feature-value__current-status.feature-value__current-status--yellow {
  color: #cfb72f;
}

.feature-value__current-status.feature-value__current-status--red {
  color: #cf452f;
}

.status-update__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.status-udpate__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.status-update__label {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
}

.status-update__current-status {
  margin-top: 8px;
  margin-bottom: 5px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.status-update__current-status.status-update__current-status--green {
  color: #38aa50;
}

.status-update__current-status.status-update__current-status--yellow {
  color: #cfb72f;
}

.status-update__current-status.status-update__current-status--red {
  color: #cf452f;
}

.status-update__header_info-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-7 {
  height: 1em;
}

.no-data {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
  color: #6c6c6c;
}

.bar-chart__row_target {
  position: absolute;
  left: 4%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  height: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #000;
  opacity: 0.25;
}

.text-block-4 {
  position: absolute;
  white-space: nowrap;
}

.bar-chart__row_target-tooltip {
  position: relative;
  bottom: 26px;
  display: none;
  height: 20px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

.header-col {
  width: 60%;
}

.header-col.is--1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header-col.is--2 {
  width: 40%;
  margin-left: 3em;
  padding-left: 2em;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3em;
}

.grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.tab-h4.clear {
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-notice {
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 1em;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
}

.text-notice__text {
  margin-bottom: 0px;
}

.story-image {
  width: 100%;
  height: 12rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
}

.story-image.is--full {
  width: 100%;
  max-height: none;
  max-width: 100%;
  margin-right: 0em;
  margin-bottom: 0em;
}

.story-image.is--modal {
  height: unset;
  max-height: 50vh;
  margin-bottom: 1.5em;
}

@media screen and (max-width: 991px) {
  .story-title.is--modal {
    font-size: 1.2em !important;
  }
}

/* .story-image.is--hidden {
  display: none;
} */

.story__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-decoration: none;
}

.story-title {
  margin-bottom: 0.4em;
  color: #2e2e2e;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
}

.story-title.is--modal {
  margin-bottom: 0em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1.4em;
}

.story-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.story-description {
  margin-bottom: 0.5em;
  line-height: 1.4;
}

.link-block {
  color: #000;
  text-decoration: underline;
}

.link-block__text {
  margin-bottom: 0px;
  color: #000;
}

.phase-tabs {
  margin-top: 2em;
  margin-right: -25px;
  margin-left: -25px;
}

.phase-tabs.is--hidden {
  display: none;
}

.phase-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  padding: 1em 2em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 6px 6px 0px 0px;
  background-color: #979797;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.1)), color-stop(15%, transparent));
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent 15%);
  -webkit-transition: all 100ms cubic-bezier(.23, 1, .32, 1);
  transition: all 100ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
}

.phase-tab:hover {
  padding-top: 0.8em;
  padding-bottom: 1.2em;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.1)), color-stop(15%, transparent));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent 15%);
}

.phase-tab.w--current {
  background-color: #000;
  background-image: none;
  color: #fff;
  font-weight: 700;
}

.phase-pane {
  padding-top: 1em;
  padding-bottom: 2em;
}

.phase-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  padding-left: 25px;
}

.phase-content {
  padding-right: 25px;
  padding-left: 25px;
  background-color: #eee;
}

.achievements-summary.is--hidden {
  display: none;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
}

.heading-2 {
  padding-top: 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.phase-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5em 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
  text-decoration: none;
}

.phase-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10px;
  height: 10px;
  margin-right: 1em;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #d1d1d1;
  color: #000;
  font-size: 9px;
}

.phase-number.is--phase-2 {
  background-color: #f5821f;
}

.phase-number.is--phase-1 {
  background-color: #f3be37;
}

.phase-number.is--active {
  width: 10px;
  height: 10px;
  background-color: #6fc69f;
}

.phase-number.is--inactive {
  width: 10px;
  height: 10px;
  background-color: #cf452f;
}

.phase-number.is--phase-1 {
  width: 10px;
  height: 10px;
  background-color: #124069;
}

.phase-number.is--phase-2 {
  width: 10px;
  height: 10px;
  background-color: #f5821f;
}

.phase-indicator {
  position: absolute;
  left: auto;
  top: -6px;
  right: 4px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tabs-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.7em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tab-legend__text {
  margin-bottom: 0px;
  line-height: 1;
}

.phase-status__text {
  margin-bottom: 0px;
}

.tab-title__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3em;
  padding-bottom: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.quotation-mark {
  width: 30px;
  height: 40px;
  margin-bottom: 0px;
  color: #25784b;
  font-size: 80px;
  line-height: 1;
}

.quotation-credit {
  margin-bottom: 0px;
  padding-right: 0.2em;
  color: #000;
  font-weight: 700;
  text-align: right;
}

.quotation {
  margin-bottom: 0.6em;
  line-height: 1.4;
}

.beneficiary-stories {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.story-read-more {
  margin-top: auto;
  color: #25784b;
  text-decoration: underline;
}

.image-caption {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-right: 16px;
  margin-bottom: 0px;
  margin-left: 16px;
  padding: 4px 16px 16px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
  line-height: 1.4;
}

.half-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 1em;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5em;
  grid-row-gap: 1.5em;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.half-grid.is--v2 {
  margin-bottom: 2em;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.bar-chart__row_phase-2-label {
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  height: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.bar-chart__row_phase-2-label.is--dummy-2 {
  left: 24%;
}

.bar-chart__row_phase-2-label.is--dummy-3 {
  left: 16%;
}

.bar-chart__row_phase-2-label.is--dummy-4 {
  left: 8%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bar-chart__row_phase-total {
  position: relative;
  bottom: 26px;
  display: none;
  height: 20px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

.tooltip-chip {
  position: absolute;
  top: -10px;
  width: 8px;
  height: 8px;
  background-color: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.story-department {
  position: absolute;
  left: 16px;
  top: 16px;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  padding: 0.4em 0.5em;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  text-decoration: none;
}

.story-department:hover {
  text-decoration: underline;
}

.story-department.is--shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.story-department__text {
  margin-bottom: 0px;
  color: #fff;
  line-height: 1.2;
}

.performance-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
}

.performance-cta__text {
  margin-bottom: 0px;
}

.performance-cta__heading {
  margin-top: 0px;
  margin-bottom: 0em;
}

.performance-cta__content {
  margin-left: 1em;
  padding-right: 2em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.performance-cta__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #25784b;
  color: #fff;
  text-decoration: none;
}

.performance-cta__button-text {
  margin-bottom: 0px;
  color: #fff;
}

.performance-cta__icon {
  margin-right: 1em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.story-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.story-modal__close {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.story-modal__close.is--button {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  margin-left: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.05);
}

.story-modal__close.is--button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.story-modal__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.story-modal__close-icon {
  width: 2em;
  height: 2em;
}

.story-modal__content {
  overflow: auto;
}

.indicator-phase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0.8em;
  padding-top: 0.8em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-value__phase_content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feature-value__phase_content.is--phase-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-value__phase_content.is--phase-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feature-value__phase_content.is--split-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-value__phase-label {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.phase-marker {
  width: 8px;
  height: 8px;
  margin-right: 0.5em;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.1);
}

.phase-marker.is--phase-1 {
  background-color: #124069;
}

.phase-marker.is--phase-2 {
  background-color: #f5821f;
}

.phase-marker.is--active {
  background-color: #38aa50;
}

.phase-marker.is--inactive {
  background-color: #cf452f;
}

.feature-value__phase-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.phased-header__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  margin-right: 1em;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.phased-header__title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #25784b;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
}

.phased-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.phase-label__period {
  color: #999;
  font-weight: 400;
}

.phase-legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1em;
  padding: 0.5em 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
}

.phase-legend__text {
  margin-bottom: 0px;
  color: #000;
  font-weight: 700;
}

.paragraph-2 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}

.phase-legend__date {
  margin-bottom: 0px;
  margin-left: 0.7em;
  padding-left: 0.7em;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.phased-header__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.phased-header__value {
  margin-top: 0px;
  padding-top: 0px;
  border-top: 1px none rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.phased-header__value.feature-value__amount--green {
  color: #38aa50;
}

.phased-header__value.feature-value__amount--yellow {
  color: #cfb72f;
}

.phased-header__value.feature-value__amount--red {
  color: #cf452f;
}

.phased-header__value.is--v2 {
  padding-top: 0px;
  border-top-style: none;
}

.phased-header__value.is--phase-1 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.story-image__placeholder {
  width: 100%;
  height: 11em;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
}

.achievement-details {
  margin-bottom: 2em;
}

.phase-cta {
  margin-top: 1.5em;
}

.phase-cta.is--hidden {
  display: none;
}

.bar-value__wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.bar-value {
  position: relative;
  top: -31px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.2em;
  margin-bottom: 0px;
  padding: 1em 0.6em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  line-height: 1;
}

.bar-value__chip {
  position: absolute;
  top: -10px;
  width: 8px;
  height: 8px;
  margin-right: auto;
  margin-left: auto;
  background-color: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bar-value__wrapper {
  transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
}

.feature-value__phase-value {
  margin-top: 0px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.feature-value__phase-value.feature-value__amount--green {
  color: #38aa50;
}

.feature-value__phase-value.feature-value__amount--yellow {
  color: #cfb72f;
}

.feature-value__phase-value.feature-value__amount--red {
  color: #cf452f;
}

.feature-value__phase-value.is--v2 {
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__phase-value.is--phase-1 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__phase-value.is--phase-2 {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.feature-value__phase-chart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 16px;
  padding-left: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-value__phase_chart-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.feature-value__phase_chart-wrapper.is--centered {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.feature-value__phase_detail {
  margin-left: 1em;
}

.story-image__wrapper {
  position: relative;
  height: 15rem;
  margin-bottom: 1em;
  border-radius: 4px;
  background-color: #eee;
}

.story-image__loading {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  border-radius: 4px;
}

.story-image__loading-grad {
  position: relative;
  left: -25%;
  width: 50%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), color-stop(50%, rgba(0, 0, 0, 0.05)), to(hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.05) 50%, hsla(0, 0%, 100%, 0));
}

@media screen and (max-width: 991px) {
  .embed-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .tabs-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tabs-wrapper.is--v2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tab-menu {
    position: relative;
    top: 0em;
    width: 100%;
    padding-right: 1.6em;
    padding-bottom: 1em;
    padding-left: 1.6em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: auto;
  }

  .tab-menu.is--v2 {
    width: 100%;
  }

  .tab-menu.is--v2b {
    position: static;
    width: 100%;
    padding-right: 1.6em;
    padding-bottom: 1em;
    padding-left: 1.6em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tab-menu.is--v2e {
    position: static;
    width: 100%;
    padding-right: 1.6em;
    padding-left: 1.6em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tab-content {
    width: 100%;
  }

  .tab-content.is--v2b {
    width: 100%;
  }

  .tab-content.is--v2e {
    width: 100%;
    margin-top: 1em;
  }

  .tab {
    margin-right: 10px;
  }

  .tab-inner {
    padding-right: 1.6em;
    padding-bottom: 0px;
    padding-left: 1.6em;
  }

  .tab-inner.is--v2 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .thirds-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .block.feature-value {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .block.block--placeholder {
    display: none;
  }

  .block.status-update {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .block.is--phased-achievement {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .block.is--phased-detail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .paragraph {
    max-width: none;
  }

  .header-description {
    max-width: none;
  }

  .logo {
    width: 250px;
  }

  .header-graphic {
    height: 150px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .header-image {
    height: 150px;
  }

  .header-col.is--1 {
    width: 100%;
  }

  .header-col.is--2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 1em;
    margin-left: 0px;
    padding-top: 2em;
    padding-left: 0em;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left-style: none;
  }

  .div-block-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .grid.is--v2 {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .tabs-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .half-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .phased-header__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .phased-header__value {
    margin-top: 0.3em;
  }

  .swiper-container {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  .tab-content {
    overflow: visible;
  }

  .tab-inner {
    padding: 20px 20px 30px;
  }

  .tab-title {
    padding-bottom: 10px;
  }

  .tab-title.is--v2 {
    font-size: 1.6em;
  }

  .thirds-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .thirds-grid.is--v2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .block.is--performance-cta {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .button.is--performance-cta {
    width: 100%;
    margin-top: 1em;
    text-align: center;
  }

  .header.is--legend {
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .uppercase-label.hide-mobile.is--menu-heading {
    padding-left: 0px;
  }

  .logo {
    width: 200px;
  }

  .header-graphic {
    height: 100px;
    padding-left: 1em;
  }

  .header-image {
    height: 100px;
  }

  .grid.is--v2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .phase-tabs {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .phase-tab {
    max-width: 33%;
    margin-right: 0.5em;
    padding: 0.8em 1em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 6px;
    background-image: none;
  }

  .phase-tab:hover {
    padding-top: 1em;
    padding-bottom: 1em;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  .phase-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0em;
    z-index: 1;
    padding: 0.5em 1.8em;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: #fff;
    box-shadow: 0 3px 7px 3px rgba(0, 0, 0, 0.1);
  }

  .phase-content {
    width: 100%;
  }

  .half-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .half-grid.is--v2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .swiper-container {
    display: block;
  }

}

@media screen and (max-width: 479px) {
  .tabs-wrapper {
    margin-top: 1em;
  }

  .tab-menu {
    /* overflow: hidden; */
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 12px;
    padding-bottom: 0em;
    padding-left: 12px;
  }

  .tab-menu.is--v2b {
    padding-right: 0.8em;
    padding-bottom: 0em;
    padding-left: 0.8em;
  }

  .tab-menu.is--v2e {
    padding-right: 0.8em;
    padding-left: 0.8em;
  }

  .tab-content {
    margin-top: 0.5em;
  }

  .tab {
    width: 100%;
  }

  .tab-inner {
    padding: 12px 14px 20px;
  }

  .tab-inner.is--v2 {
    padding-top: 1em;
    padding-right: 12px;
    padding-left: 12px;
  }

  .tab-title.is--v2 {
    font-size: 1.5em;
  }

  .thirds-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .header {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .page-header {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .department-menu-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 44px;
    margin-right: 12px;
    margin-bottom: 10px;
    margin-left: 12px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .hide-mobile {
    display: none;
  }

  .logo {
    width: 50%;
    padding: 1em;
    border-radius: 0px 10px 10px 0px;
    background-color: #fff;
    box-shadow: 0 3px 12px -10px #000;
  }

  .header-graphic {
    margin-right: 12px;
    margin-bottom: 1em;
    margin-left: 12px;
    padding-left: 0em;
  }

  .header-image {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .story-image {
    width: 100%;
    max-height: 15em;
    margin-right: 0em;
  }

  .div-block-9 {
    margin-top: 1em;
  }

  .phase-status {
    margin-top: 0.5em;
  }

  .tabs-header {
    display: none;
  }

  .tab-title__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .half-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

#w-node-_014fcd27-4205-91c0-ac79-6f263d7d7a62-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a1c52571-e82d-61e4-eeb2-6601c311b3ab-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d299c5ec-76f9-0800-f9a4-03d16802dfa2-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_50903bab-0a6d-ce06-971f-a341e2241ce5-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_50903bab-0a6d-ce06-971f-a341e2241cfd-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a19-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a33-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a3c-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_98b575c9-cc04-4407-35c5-e0e05fbf112b-6fe10c13 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_98b575c9-cc04-4407-35c5-e0e05fbf117c-6fe10c13 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_76631715-09f4-ce95-e3a9-434d2f2d96d3-6fe10c13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_63d38ce8-e983-33f6-c032-a79a1b2ff589-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-ebc4279d-55bd-0aba-1986-d98c1a638aa4-cb6b7abd {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_8975ed92-49b2-dd19-79de-b37d9478c407-cb6b7abd {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-dae6cb4c-9f38-069e-cd37-e1c6ee448510-cb6b7abd {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-e25176e2-d78a-02e0-2f81-4b65da920393-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5c6f5c07-0896-963e-6355-339e663f509e-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d9ab061b-c938-a877-004d-77b71cf86f18-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a221d189-9081-31f0-e9fa-98711361213d-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4a687334-b312-746a-ddc9-7de0692940d2-cb6b7abd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dbf18d66-e54c-23cd-910e-51969e86976d-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-fda0bf96-41ea-8594-5d9f-1c496d2fdc15-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_7a63e846-38aa-cc46-5059-a7c42da9f5fa-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-a0f220be-8ad0-32d1-1381-cca3c5d1e9d7-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_84c728b7-a5b0-d322-3ebb-bb538d81d960-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_84c728b7-a5b0-d322-3ebb-bb538d81d9de-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_4dea0fe4-1112-440a-650e-05b69f5c0d9a-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_8866321d-efb9-2b83-31c3-58ec9ce291c2-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8866321d-efb9-2b83-31c3-58ec9ce291c3-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8866321d-efb9-2b83-31c3-58ec9ce291d4-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_8866321d-efb9-2b83-31c3-58ec9ce29252-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_2c97a37d-9d3a-3381-7c6f-43de270cfc9d-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2c97a37d-9d3a-3381-7c6f-43de270cfc9e-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2c97a37d-9d3a-3381-7c6f-43de270cfcaf-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_2c97a37d-9d3a-3381-7c6f-43de270cfd2d-828480e0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5c6f5c07-0896-963e-6355-339e663f509e-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d9ab061b-c938-a877-004d-77b71cf86f18-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a221d189-9081-31f0-e9fa-98711361213d-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-828480e0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_63d38ce8-e983-33f6-c032-a79a1b2ff589-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-ebc4279d-55bd-0aba-1986-d98c1a638aa4-adbe14c8 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_8975ed92-49b2-dd19-79de-b37d9478c407-adbe14c8 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-dae6cb4c-9f38-069e-cd37-e1c6ee448510-adbe14c8 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-e25176e2-d78a-02e0-2f81-4b65da920393-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5c6f5c07-0896-963e-6355-339e663f509e-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d9ab061b-c938-a877-004d-77b71cf86f18-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a221d189-9081-31f0-e9fa-98711361213d-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4a687334-b312-746a-ddc9-7de0692940d2-adbe14c8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-fa7a4dec-579f-b402-8072-358a0ea47c54-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-fa7a4dec-579f-b402-8072-358a0ea47c82-280de287 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-fa7a4dec-579f-b402-8072-358a0ea47cdd-280de287 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-fa7a4dec-579f-b402-8072-358a0ea47d38-280de287 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-fa7a4dec-579f-b402-8072-358a0ea47d9a-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-e25176e2-d78a-02e0-2f81-4b65da920393-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5c6f5c07-0896-963e-6355-339e663f509e-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d9ab061b-c938-a877-004d-77b71cf86f18-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a221d189-9081-31f0-e9fa-98711361213d-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-280de287 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

@media screen and (max-width: 991px) {
  #w-node-f8082f05-3a88-1712-a4aa-1e99d491d9fb-6fe10c13 {
    -ms-grid-column-span: 4;
    grid-column-end: 4;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491da05-6fe10c13 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_014fcd27-4205-91c0-ac79-6f263d7d7a62-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a1c52571-e82d-61e4-eeb2-6601c311b3ab-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d299c5ec-76f9-0800-f9a4-03d16802dfa2-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_50903bab-0a6d-ce06-971f-a341e2241ce5-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_50903bab-0a6d-ce06-971f-a341e2241cfd-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a19-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a33-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a3c-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491d9fb-cb6b7abd {
    -ms-grid-column-span: 4;
    grid-column-end: 4;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491da05-cb6b7abd {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4a687334-b312-746a-ddc9-7de0692940d2-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491d9fb-828480e0 {
    -ms-grid-column-span: 4;
    grid-column-end: 4;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491da05-828480e0 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491d9fb-adbe14c8 {
    -ms-grid-column-span: 4;
    grid-column-end: 4;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491da05-adbe14c8 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4a687334-b312-746a-ddc9-7de0692940d2-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491d9fb-280de287 {
    -ms-grid-column-span: 4;
    grid-column-end: 4;
  }

  #w-node-f8082f05-3a88-1712-a4aa-1e99d491da05-280de287 {
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-fa7a4dec-579f-b402-8072-358a0ea47c54-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-fa7a4dec-579f-b402-8072-358a0ea47d9a-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_014fcd27-4205-91c0-ac79-6f263d7d7a62-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a1c52571-e82d-61e4-eeb2-6601c311b3ab-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d299c5ec-76f9-0800-f9a4-03d16802dfa2-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_50903bab-0a6d-ce06-971f-a341e2241ce5-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_50903bab-0a6d-ce06-971f-a341e2241cfd-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a19-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a33-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_03a49d2c-7e7b-9d91-2b9f-dab5f2f91a3c-6fe10c13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4a687334-b312-746a-ddc9-7de0692940d2-cb6b7abd {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-828480e0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_26952b44-cd9b-04e1-64b6-d9ee4975adca-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_92b5e6e4-f438-804c-ebcf-e193c64f8a29-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0e7609e3-968d-e676-73ff-3a9b876486d4-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_9b0dcc1e-84b8-14c8-4ea5-ad6ce609d503-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_168c39e9-a8ce-e0c1-0a9b-e172a0e4b734-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4a687334-b312-746a-ddc9-7de0692940d2-adbe14c8 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa7a4dec-579f-b402-8072-358a0ea47c54-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa7a4dec-579f-b402-8072-358a0ea47d9a-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e25176e2-d78a-02e0-2f81-4b65da920393-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4f03e695-b5f8-c4bb-c90c-b14a447f17f7-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5c6f5c07-0896-963e-6355-339e663f509e-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5e14805d-4d88-d9d8-715b-d346f30618cc-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_53be9f66-5b00-d848-9fc0-57a140434ce3-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_23cf633f-9c5d-2c18-bd19-87306c7286cb-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dbe8e494-cfd8-7b6b-21d5-b6176fbfa6c6-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d9ab061b-c938-a877-004d-77b71cf86f18-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a221d189-9081-31f0-e9fa-98711361213d-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d3ead9b9-c615-9ed1-1d27-500f9a7935a5-280de287 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

